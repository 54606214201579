import React from 'react';
import styles from './LoginPage.module.css';

function Header() {
  return (
    <header className={styles.header}>
      <img src="https://home.ambicam.com/_next/static/media/ambicam.9b63e6bb.png" alt="Company Logo" className={styles.logo} />
      <div className={styles.decorativeElement}></div>
    </header>
  );
}

export default Header;