import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Box, ChakraProvider, Flex, useMediaQuery } from '@chakra-ui/react';
import LoginPage from './pages/LoginPage/LoginPage';
import SignUpPage from './pages/SignUpPage/SignUpPage';
import theme from './assets/styles/theme';
import AlertsTable from './pages/AlertPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundAndCancellation from './pages/RefundAndCancellation';
import TermsAndConditions from './pages/TermsAndConditions';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Dashboard from './pages/Dashboard/Dashboard';
import AIDashboard from './pages/AI-Dashboard/Dashboard';
import MultiScreenDesktop from './pages/MultiScreen/MultiScreen';
import AIEvents from './components/AIEvents/AIEvents';
import NotificationDialog from './components/NotificationDialog';
import Notification from './components/Notification/Notification';
import Reports from './components/Reports/Reports';
import ArcisGPT from './components/ArcisGPT/ArcisGPT';
import Dashy from './pages/dashy';
import Geomap from './pages/geomap';
import Subscription from './pages/Subscription';
import camera from './assets/images/camera.svg';
import multiscreen from './assets/images/multiscreen.svg';
import aievents from './assets/images/aievents.svg';
import profile from './assets/images/profile.svg';
import ProfilePage from './components/ProfilePage/ProfilePage';
import Profile from './components/ProfilePage/ProfileSection/Profile';

function AppContent() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Check if user is logged in (e.g., check local storage or API)
    const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedInStatus);
  }, []);

  const showHeader = !['/', '/signup'].includes(location.pathname);
  const showSidebar = !['/', '/signup'].includes(location.pathname);
  const [isMobile] = useMediaQuery('(max-width: 48em)'); // Mobile view breakpoint
  const isLoginPage = location.pathname === '/';
  const sidebarWidth = '200px'; // Adjust as needed for your design
  return (
    <>
      {showHeader && <Header />}
      {/* {showSidebar && <Sidebar />} */}
      {!isMobile && showSidebar && (
        <Box
          as="aside"
          width={sidebarWidth}
          display={{ base: 'none', md: 'block' }}
          position="sticky"
          top="0"
          height="100vh"
          overflowY="auto"
          zIndex="1"
          bg="white"
        >
          <Sidebar />
        </Box>
      )}
      {isMobile && showSidebar && (
        <Box
          as="footer"
          width="100%"
          position="fixed"
          bottom="0"
          left="0"
          height="auto"
          bg="white"
          boxShadow="20px 0px 10px rgba(0, 0, 0, 0.1)"
          zIndex="1000"
          padding="0.5rem"
          display="flex"
          flexDirection="column"
        >
          {/* Toggle Button */}
          <Flex justify="space-evenly" align="center">
            <Box as="a" href="/dashboard" display="flex" flexDirection="column" alignItems="center" padding="0.5rem" style={{ textDecoration: "none", color: "inherit" }}>
              <img src={camera} alt="Camera" />
              <span>Camera</span>
            </Box>
            <Box as="a" href="/multiple" display="flex" flexDirection="column" alignItems="center" padding="0.5rem" style={{ textDecoration: "none", color: "inherit" }}>
              <img src={multiscreen} alt="MultiScreen" />
              <span>Multiple</span>
            </Box>
            <Box as="a" href="/AIEvents" display="flex" flexDirection="column" alignItems="center" padding="0.5rem" style={{ textDecoration: "none", color: "inherit" }}>
              <img src={aievents} alt="AIEvents" />
              <span>AIEvents</span>
            </Box>
            <Box as="a" href="/profile" display="flex" flexDirection="column" alignItems="center" padding="0.5rem" style={{ textDecoration: "none", color: "inherit" }}>
              <img src={profile} alt="Profile" />
              <span>Profile</span>
            </Box>
            {/* <IconButton
              aria-label="Toggle menu"
              icon={<CiMenuBurger />}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              style={{ border: "none", background: "none", fontSize: "20px" }}
            /> */}
          </Flex>
        </Box>
      )}

      <Box
        as="main"
        flex="1"
        position="fixed"
        left={isLoginPage || isMobile ? '0' : '220px'}
        top={isLoginPage ? '0' : '60px'}
        bottom={isMobile ? '80px' : '0px'}
        // width={isLoginPage ? '100%' : {
        //   base: '100%',
        //   lg: isCollapsed ? 'calc(100% - 80px)' : 'calc(100% - 280px)',
        // }}
        // width={'calc(100% - 220px)'}
        width={isLoginPage || isMobile ? '100%' : 'calc(100% - 220px)'}
        transition="left 0.2s, width 0.2s"
        overflowY="auto"
        flexWrap="wrap"
      >
        <Routes>
          <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/ai-dashboard" element={<AIDashboard />} />
          <Route path='/AIEvents' element={<AIEvents />} />
          <Route path='/notification' element={<Notification />} />
          <Route path='/arcisgpt' element={<ArcisGPT />} />
          <Route path='/reports' element={<Reports />} />
          <Route path='/dashy' element={<Dashy />} />
          {/* <Route path="/geomap" element={<Geomap />} /> */}
          {/* <Route path="/subscription" element={<Subscription />} /> */}
          {/* <Route path="/dash" element={< DashboardPage/>} /> */}
          <Route path="/multiple" element={<MultiScreenDesktop />} />
          <Route path="/alert" element={<AlertsTable />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/refundandcancellation" element={<RefundAndCancellation />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/profile" element={<ProfilePage />} />+
          
          <Route
            path="/profile"
            element={isMobile ? <Navigate to="/profileSection" /> : <ProfilePage />}
          />
          <Route
            path="/profileSection"
            element={isMobile ? <Profile /> : <Navigate to="/profile" />}
          />
        </Routes>
      </Box>
    </>
  );
}

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <AppContent />
      </Router>
    </ChakraProvider>
  );
}

export default App;
